@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 320 20% 96%;
    --foreground: 320 10% 23%;
    
    --card: 320 20% 96%;
    --card-foreground: 320 10% 23%;
 
    --popover: 320 20% 96%;
    --popover-foreground: 320 10% 23%;
 
    --primary: 320 90% 34%;
    --primary-foreground: 0 0% 100%;
 
    --secondary: 217 19% 27%;
    --secondary-foreground: 0 0% 100%;
 
    --muted: 320 20% 96%;
    --muted-foreground: 320 10% 63%;
 
    --accent: 320 83% 51%;
    --accent-foreground: 0 0% 100%;
 
    --destructive: 0 84% 60%;
    --destructive-foreground: 0 0% 98%;
 
    --border: 320 20% 87%;
    --input: 320 20% 87%;
    --ring: 320 90% 34%;
 
    --radius: 0.5rem;
  }

  * {
    @apply border-border;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply bg-background text-foreground;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px; /* Adjust this value based on your navbar height */
}